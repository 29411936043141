import Board from "./Board/Board";
import "./App.css";
import logo from "./logo.png";

function App() {
    return (
        <div className="app">
            <header className="header">
                <nav className="navbar navbar-light bg-light">
                    <span className="navbar-brand mb-0 m-auto h1">
                        <div className="d-flex align-items-center">
                            <span>
                                <img
                                    src={logo}
                                    className="App-logo"
                                    alt="logo"
                                    width="40"
                                />
                            </span>
                            <span className="ml-3 app-title">
                                <strong>Harvest Festival Auction 2024</strong>
                            </span>
                        </div>
                    </span>
                </nav>
            </header>
            <Board></Board>
        </div>
    );
}

export default App;
