import React from "react";
import NumberFormat from "react-number-format";
import "./Board.css";
import soldImg from "./../sold.png";

class Board extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemInput: "",
            activeItem: null,
            toggleSold: false,
            items: [],
        };

        // This binding is necessary to make `this` work in the callback
        this.toggleSold = this.toggleSold.bind(this);
        this.setSelectedItem = this.setSelectedItem.bind(this);
        this.updateAmount = this.updateAmount.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentDidMount() {
        fetch("/api/items")
        // fetch("/items.json")
            .then((res) => res.json())
            .then((result) => {
                this.setState({
                    items: result,
                });
            });
    }

    toggleSold() {
        this.setState((prevState) => ({
            toggleSold: !prevState.toggleSold,
        }));
    }

    setSelectedItem(event) {
        this.setState({ itemInput: event.target.value });
        if (event.target.value && event.target.value !== "") {
            let item = this.state.items.filter(
                (x) => x.itemNo === event.target.value
            );
            this.setState({
                activeItem: Object.assign({}, item[0]),
                toggleSold: false,
            });
        }
    }

    updateAmount(amount) {
        let item = this.state.activeItem;
        item.bidAmount = item.bidAmount + amount;
        this.setState({ activeItem: item });
    }

    reset() {
        this.setState({
            itemInput: "",
            activeItem: null,
            toggleSold: false,
        });
    }

    render() {
        return (
            <main role="main" className="content">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-6">
                            <div className="align-items-center d-flex h-100 justify-content-center">
                                {this.state.activeItem && (
                                    <div>
                                        <img
                                            src={
                                                "/itemimages/" +
                                                this.state.activeItem.itemNo +
                                                (this.state.activeItem
                                                    .imageFormat
                                                    ? "." +
                                                      this.state.activeItem
                                                          .imageFormat
                                                    : ".jpeg")
                                            }
                                            className="item-image"
                                            alt="Auction Item"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            {this.state.activeItem && (
                                <div className="align-items-center d-flex flex-column h-100 justify-content-center">
                                    <div className="item-no text-center">
                                        Item No. {this.state.activeItem.itemNo}
                                    </div>
                                    <div className="item-name text-center">
                                        {this.state.activeItem.itemName}
                                    </div>
                                    <div className="bid-amount text-center">
                                        {this.state.activeItem.saleAmount && (
                                            <NumberFormat
                                                value={
                                                    this.state.activeItem
                                                        .saleAmount
                                                }
                                                displayType={"text"}
                                                thousandsGroupStyle="lakh"
                                                thousandSeparator={true}
                                                prefix={"₹"}
                                            />
                                        )}
                                        {!this.state.activeItem.saleAmount && (
                                            <NumberFormat
                                                value={
                                                    this.state.activeItem
                                                        .bidAmount
                                                }
                                                displayType={"text"}
                                                thousandsGroupStyle="lakh"
                                                thousandSeparator={true}
                                                prefix={"₹"}
                                            />
                                        )}
                                    </div>
                                    {(this.state.activeItem.saleAmount ||
                                        this.state.toggleSold) && (
                                        <div className="sold-image">
                                            <img
                                                src={soldImg}
                                                className="item-image"
                                                alt="Auction Item"
                                                width="200px"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8">
                            {this.state.activeItem && (
                                <div className="py-3">
                                    <button
                                        className="btn btn-light border border-dark mr-2"
                                        onClick={() => this.updateAmount(50)}
                                    >
                                        + 50
                                    </button>
                                    <button
                                        className="btn btn-light border border-dark mr-2"
                                        onClick={() => this.updateAmount(100)}
                                    >
                                        + 100
                                    </button>
                                    <button
                                        className="btn btn-light border border-dark mr-2"
                                        onClick={() => this.updateAmount(500)}
                                    >
                                        + 500
                                    </button>
                                    <button
                                        className="btn btn-light border border-dark mr-2"
                                        onClick={() => this.updateAmount(1000)}
                                    >
                                        + 1000
                                    </button>
                                    <button
                                        className="btn btn-light border border-danger ml-5 mr-2"
                                        onClick={() => this.updateAmount(-50)}
                                    >
                                        -50
                                    </button>
                                    <button
                                        className="btn btn-light border border-danger mr-2"
                                        onClick={() => this.updateAmount(-100)}
                                    >
                                        -100
                                    </button>
                                    {/* <button
                                        className="btn btn-light border border-dark mr-2"
                                        onClick={() => this.updateAmount(2000)}
                                    >
                                        + 2000
                                    </button>
                                    <button
                                        className="btn btn-light border border-dark mr-2"
                                        onClick={() => this.updateAmount(5000)}
                                    >
                                        + 5000
                                    </button>
                                    <button
                                        className="btn btn-light border border-dark mr-5"
                                        onClick={() => this.updateAmount(10000)}
                                    >
                                        + 10000
                                    </button> */}
                                    <button
                                        className="btn btn-light border border-danger ml-5"
                                        onClick={this.toggleSold}
                                    >
                                        {this.state.toggleSold && "Remove"} Sold
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="col-4">
                            <div className="py-3">
                                <div className="form-inline">
                                    <input
                                        type="text"
                                        className="form-control m-0 ml-auto"
                                        value={this.state.itemInput}
                                        onChange={this.setSelectedItem}
                                    />
                                    <button
                                        className="btn btn-light border border-dark ml-2"
                                        onClick={this.reset}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
export default Board;
